import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Vasco = lazy(() => import('sections/hero/Vasco.js'));
const Cita = lazy(() => import('sections/testimonials/Cita.js'));
const VascoSolutions = lazy(() => import('sections/services/VascoSolutions.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>VASCO Solutions: Empresa de software y Ciberseguridad en Colombia</title>
          <meta name="description" content="VASCO Solutions, líder en tecnología en Santa Rosa de Cabal Colombia. Especializada en hosting, desarrollo de software y consultoría en ciberseguridad." />
        </Helmet>
        <Layout>
          <Vasco />
          <Cita />          
          <VascoSolutions />          
          <Llamenos />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`