import React, { lazy, Suspense } from 'react';
import Navbar from 'components/navbar';
import Footer from 'components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'scss/abstract.scss';

// Cargar el CookieBanner de manera diferida
const CookieBanner = lazy(() => import('components/cookie-banner'));

const Layout = ({ children, isHome, sections }) => (
  <div id="main">
    <Navbar scroll={isHome} sections={sections} />
    {/* <PageRevealer /> */}
    <div>{children}</div>
    <Footer />
    {/* Cargar el CookieBanner de manera diferida */}
    <Suspense fallback={<div>Loading...</div>}>
      <CookieBanner />
    </Suspense>    
  </div>
);

export default Layout;
